<template>
  <div class="base-page">
    <div v-if="'success' !== status" class="ui-loader__wrapper">
      <span class="ui-loader sticky" />
    </div>
    <div v-else class="base-page__container">
      <div class="base-page__posts">
        <template
          v-for="(postPreview, index) in posts"
          :key="postPreview.documentId"
        >
          <PostPreview :post-preview="postPreview" />
          <Subscription
            v-if="
              data.posts.length > 12
                ? index === 12
                : index === data.posts.length - 1
            "
            class="base-page__subscription"
          />
        </template>
      </div>
      <Pagination
        :pages="data.pagination.pageCount"
        :current-page="blogStore.params.page"
        @on-page-change="onPageChange"
        @on-load-more="onShowMore"
      />
      <LatestPosts :post-previews="data.latest" />
    </div>
  </div>
</template>

<script setup>
import { useStrapiApi } from "~/uses/useMyFetch";
import { getPostsQuery } from "~/nuxt_modules/blog/utils/query.utils";
import PostPreview from "~/nuxt_modules/blog/ui/components/PostPreview.vue";
import Subscription from "~/nuxt_modules/blog/ui/components/Subscription.vue";
import { useBlogStore } from "~/nuxt_modules/blog/store/blogStore";
import Pagination from "~/modules/shared/pagination/Pagination.vue";
import LatestPosts from "~/nuxt_modules/blog/ui/components/LatestPosts.vue";

const props = defineProps({
  filters: { type: Object, required: true },
});

const route = useRoute();
const blogStore = useBlogStore();

const appendedPosts = ref([]);
const currentPage = ref(1);

watch(
  () => route.params,
  () => {
    blogStore.initParams();
    currentPage.value = blogStore.params.page;
  },
  { immediate: true },
);

const { data, status } = useAsyncData(
  "fetchPostsData",
  async () => {
    appendedPosts.value = [];

    const [posts, latest] = await Promise.all([
      useStrapiApi(
        `/blog/posts?${getPostsQuery(
          { ...blogStore.getRequestParams() },
          props.filters,
        )}`,
      ),
      useStrapiApi(`/blog/posts?${getPostsQuery({ page: 1, pageSize: 4 })}`),
    ]);

    return {
      posts: posts.data,
      latest: latest.data,
      pagination: posts.meta.pagination,
    };
  },
  { watch: [() => route.params], immediate: true },
);

const posts = computed(() => {
  return [...data.value.posts, ...appendedPosts.value];
});

const onShowMore = async () => {
  currentPage.value += 1;

  const { data: appendedData } = await useStrapiApi(
    `/blog/posts?${getPostsQuery(
      {
        page: currentPage.value,
        pageSize: blogStore.params.limit,
      },
      props.filters,
    )}`,
  );

  appendedPosts.value.push(...appendedData);
};

const onPageChange = (page) => blogStore.setPage(page);
</script>

<style scoped lang="scss">
.base-page {
  @extend %padding-wrp;

  &__container {
    @extend %width-main;

    @include flex-container(column, flex-start);
    gap: 24px;

    padding-bottom: 16px;
    margin-inline: auto;
  }

  &__posts {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(348px, 1fr));
    gap: 16px;

    @include mobile {
      grid-template-columns: repeat(auto-fill, minmax(164px, 1fr));
    }
  }

  &__subscription {
    grid-column: 1 / -1;
  }
}
</style>
